import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23 11.935L9 4v3.916l12.88 7.074v1.075L9 23.083V27l14-7.878v-7.187z"
        fill="#173D3D"
      />
    </svg>
  );
}

export default SvgComponent;
