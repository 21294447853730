import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      width={32}
      height={32}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25 24H13v-2h12v2zM19 10H7V8h12v2zM25 17H7v-2h18v2z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgComponent;
