import * as React from "react";

/**
 * Local Components
 */
import Head from "../components/globals/head";
import Nav from "../components/globals/nav";
import Footer from "../components/globals/footer";

/**
 * Local styles
 */
import { PageWrapper } from "../components/globals/wrappers";
import Cursor from "../components/cursor";
import { hoverChange } from "../functions/util";
import Awwwards from "../components/awwwards";

/**
 * Layout Component
 *
 * @param {Object} props
 * @param {any} props.children
 * @param {Object} props.pageContext
 * @returns page layouts
 */
export default function Layout({ children, ...rest }) {
  React.useEffect(() => {
    hoverChange(null);
    return () => hoverChange(null);
  }, []);
  return (
    <>
      <Head context={rest} />
      <Nav context={rest} />
      <PageWrapper>
        <main id="main">{children}</main>
      </PageWrapper>
      <Footer context={rest} />
      <Cursor />
      <Awwwards />
    </>
  );
}
