require("dotenv").config({
  path: `.env`,
});

const prismicHtmlSerializer = require("./src/gatsby/html-serializer");
const { createProxyMiddleware } = require("http-proxy-middleware");

/**
 * SAMPLE .env file

PRISMIC_REPO=
PRISMIC_API_KEY=""

 */

module.exports = {
  siteMetadata: {
    title: "Cramdyn Studio",
    short_name: "Cramdyn",
    description: "Cramdyn Studio",
    siteUrl: "https://cramdyn.john.design/",
    author: "Cramdyn Design Studio",
    ogImage: "",
  },
  // for avoiding CORS while developing Netlify Functions locally
  // read more: https://www.gatsbyjs.org/docs/api-proxy/#advanced-proxying
  developMiddleware: (app) => {
    app.use(
      "/.netlify/functions/",
      createProxyMiddleware({
        target: "http://localhost:9000",
        pathRewrite: {
          "/.netlify/functions/": "",
        },
      })
    );
  },
  plugins: [
    {
      resolve: "gatsby-source-prismic",
      options: {
        repositoryName: `${process.env.PRISMIC_REPO}`,
        accessToken: `${process.env.PRISMIC_API_KEY}`,
        linkResolver: require("./src/functions/linkResolver").linkResolver,
        htmlSerializer: () => prismicHtmlSerializer,
        schemas: {
          page: require("./src/schemas/page.json"),
          homepage: require("./src/schemas/homepage.json"),
          studiopage: require("./src/schemas/studiopage.json"),
          project: require("./src/schemas/project.json"),
          contactpage: require("./src/schemas/contactpage.json"),
          workpage: require("./src/schemas/workpage.json"),
        },
        lang: "en-us",
      },
    },
    `gatsby-remark-images`,
    {
      resolve: "gatsby-plugin-manifest",
      options: {
        title: "Cramdyn Studio",
        short_name: "Cramdyn",
        start_url: `/`,
        background_color: `#ffffff`,
        theme_color: `#000000`,
        display: `standalone`,
        icon: "src/images/icon.png",
      },
    },
    "gatsby-plugin-sharp",
    "gatsby-plugin-react-helmet",
    "gatsby-plugin-sitemap",
    "gatsby-transformer-sharp",
    {
      resolve: "gatsby-source-filesystem",
      options: {
        name: "images",
        path: `${__dirname}/src/images/`,
      },
      __key: "images",
    },
    {
      resolve: `gatsby-plugin-styled-components`,
      options: {
        displayName: false,
      },
    },
    "gatsby-plugin-layouts",
    `gatsby-plugin-netlify`,
    `gatsby-plugin-preact`,
    `gatsby-plugin-remove-serviceworker`,
    `gatsby-plugin-image`,
    {
      resolve: "gatsby-plugin-webpack-bundle-analyser-v2",
      options: {
        devMode: false,
        analyzerMode: "server",
        analyzerPort: "8888",
        defaultSizes: "gzip",
      },
    },
    {
      resolve: `gatsby-plugin-google-analytics`,
      options: {
        trackingId: "UA-151670142-1",
      },
    },
  ],
};
