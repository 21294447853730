import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M28 11.053L15.72 4v3.481l10.086 5.443V14.7H3v2.667h22.806v1.778L15.72 24.518V28L28 20.997v-9.944z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgComponent;
