import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      width={25}
      height={31}
      viewBox="0 0 25 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.937 10.061l-7.43-7.432L16.63.508l7.43 7.432-2.122 2.121zm-21.5 7.376L12.51 5.258l2.13 2.112L3.005 19.108l.008 2.166.671.671h2.122l11.67-11.727 2.127 2.117-12.11 12.168-.44.442H2.44l-.439-.44-1.548-1.547-.437-.437-.002-.618-.012-3.405-.002-.62.437-.441zm.647 13.056H21.52v-3H1.084v3z"
        fill="#173D3D"
      />
    </svg>
  );
}

export default SvgComponent;
