import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      width={162}
      height={32}
      viewBox="0 0 162 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 24.837C0 28.787 3.341 32 7.449 32c4.107 0 7.449-3.214 7.45-7.163v-3.192h-3.201v3.192c0 2.253-1.906 4.086-4.249 4.086s-4.248-1.833-4.248-4.086V7.163c0-2.254 1.905-4.086 4.248-4.086a4.37 4.37 0 013.006 1.195 3.982 3.982 0 011.243 2.89v3.192h3.2V7.163c0-1.916-.775-3.714-2.18-5.066C11.332.764 9.412 0 7.448 0 3.342 0 0 3.213 0 7.163v17.674zM154.005.542v.75h-1.965v5.66h-.838v-5.66h-1.966v-.75h4.769zm7.389 0v6.41h-.792V1.545l-2.05 5.315h-.652l-2.049-5.36v5.45h-.792V.542h1.192l1.994 5.216 1.984-5.216h1.165zM55.571 31.355L50.553.645h-7.938l-5.019 30.71h3.102l1.506-9.301h8.713l1.552 9.3h3.102zm-5.018-11.758h-7.938l2.691-16.451h2.555l2.692 16.451zM83.819 3.102h-2.327l-5.84 28.253h-5.794L64.064 3.102h-2.326v28.253H58.59V.645h8.075l5.657 28.253h.867L78.892.645h8.075v30.71H83.82V3.102zm15.287 28.253c5.474 0 7.664-2.72 7.664-8.994V9.638c0-5.966-2.19-8.993-7.664-8.993h-7.71v30.71h7.71zm0-2.457h-4.562V3.146h4.562c3.695 0 4.516 2.456 4.516 6.492v12.723c0 4.3-1.095 6.537-4.516 6.537zm15.743 2.457v-13.6L108.052.645h3.285l5.064 12.898L121.51.645h3.239l-6.751 17.11v13.6h-3.149zm12.351 0h3.148V3.102h2.509l6.615 28.253H147V.645h-3.148v28.253h-2.008L135.412.645H127.2v30.71zM34.7 9.638c0 4.914-1.505 7.239-4.607 8.028l4.927 13.688h-3.24l-4.79-13.38H22.52v13.38h-3.193V.645h7.71c5.247 0 7.664 2.15 7.664 8.16v.833zM22.52 3.102v12.415h4.517c3.193 0 4.516-1.579 4.516-5.878v-.834c0-4.08-1.05-5.703-4.516-5.703h-4.518z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgComponent;
