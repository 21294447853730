import React from "react";
import Helmet from "react-helmet";
import useSiteMeta from "../../hooks/use-site-meta";

/**
 * Head component
 *
 * @param {Object} props
 */
function Head({ context }) {
  const {
    data: { site_title, description, site_url, site_image },
  } = useSiteMeta();

  return (
    <Helmet
      title={site_title}
      meta={[
        // Basics
        {
          name: "viewport",
          content: "width=device-width, initial-scale=1, shrink-to-fit=yes",
        },
        { name: "title", content: site_title },
        { name: "description", content: description },
        // Open Graph / Facebook
        { property: "og:type", content: "website" },
        { property: "og:url", content: site_url },
        { property: "og:description", content: description },
        { property: "og:image", content: site_image.url },
        // Twitter
        { property: "twitter:card", content: "summary_large_image" },
        { property: "twitter:url", content: site_url },
        { property: "twitter:description", content: description },
        { property: "twitter:image", content: site_image.url },
      ]}
    />
  );
}

export default Head;
