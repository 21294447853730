import React from "react";
import { m, LazyMotion, domAnimation } from "framer-motion";
import { hoverChange } from "../../functions/util";

function LinkExternal({ href, blank, children, hoverData, ...rest }) {
  return (
    <LazyMotion features={domAnimation}>
      <m.a
        href={href}
        target={blank && "_blank"}
        rel={blank && "nofollow"}
        onMouseEnter={() => hoverChange(hoverData ? hoverData : "link")}
        onMouseUp={() => hoverChange(null)}
        onMouseLeave={() => hoverChange(null)}
        {...rest}
      >
        {children}
      </m.a>
    </LazyMotion>
  );
}

export default LinkExternal;
