import React from "react";
import { Link as GatsbyLink } from "gatsby";
import { hoverChange } from "../../functions/util";

function Link({ to, children, hoverData, ...rest }) {
  return (
    <GatsbyLink
      to={to}
      onMouseEnter={() => hoverChange(hoverData ? hoverData : "link")}
      onMouseUp={() => hoverChange(null)}
      onMouseLeave={() => hoverChange(null)}
      {...rest}
    >
      {children}
    </GatsbyLink>
  );
}

export default Link;
