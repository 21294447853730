import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.993 28.93l5.645.07L27 4h-3.458l-8.924 22.655h-1.775l-5.366-10.46H4l6.993 12.735z"
        fill="#173D3D"
      />
    </svg>
  );
}

export default SvgComponent;
