import { useStaticQuery, graphql } from "gatsby";

export const useSiteMeta = () => {
  const data = useStaticQuery(
    graphql`
      query querySiteMeta {
        prismicHomepage {
          id
          data {
            site_url
            site_title
            short_name
            description
            site_image {
              url
            }
          }
        }
      }
    `
  );

  return data.prismicHomepage;
};

export default useSiteMeta;
