// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-prismic-contactpage-uid-js": () => import("./../../../src/pages/{PrismicContactpage.uid}.js" /* webpackChunkName: "component---src-pages-prismic-contactpage-uid-js" */),
  "component---src-pages-prismic-page-uid-js": () => import("./../../../src/pages/{PrismicPage.uid}.js" /* webpackChunkName: "component---src-pages-prismic-page-uid-js" */),
  "component---src-pages-prismic-studiopage-uid-js": () => import("./../../../src/pages/{PrismicStudiopage.uid}.js" /* webpackChunkName: "component---src-pages-prismic-studiopage-uid-js" */),
  "component---src-pages-work-index-js": () => import("./../../../src/pages/work/index.js" /* webpackChunkName: "component---src-pages-work-index-js" */),
  "component---src-pages-work-prismic-project-uid-js": () => import("./../../../src/pages/work/{PrismicProject.uid}.js" /* webpackChunkName: "component---src-pages-work-prismic-project-uid-js" */)
}

