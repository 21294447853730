import { createGlobalStyle } from "styled-components";
import { clampBuilder } from "../functions/util";

const BaseStyles = createGlobalStyle`

  /* :root {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    font-weight: 400;
  } */

  html {
    --main-background: ${({ theme }) => theme.colors.green};
    --main-foreground: ${({ theme }) => theme.colors.white};
    --main-nav-feather: ${({ theme }) => theme.colors.green};
    --layout-gutter: calc(${({ theme }) =>
      theme.layout.outerWrapperMobile} * 2);
    --layout-outer: calc(100vw - var(--layout-gutter));
    --layout-col: calc(var(--layout-outer) / 12);

    @media ${({ theme }) => theme.device.tablet} {
      --layout-gutter: calc(${({ theme }) => theme.layout.outerWrapper} * 2);
      --layout-outer: calc(100vw - var(--layout-gutter));
      --layout-col: calc(var(--layout-outer) / 12);
    }
  }

  [data-theme='light'] {
    --main-background: ${({ theme }) => theme.colors.white};
    --main-foreground: ${({ theme }) => theme.colors.black};
    --main-nav-feather: transparent;
  }

  @media (hover: hover) {
    * { cursor: none; }
  }

  ::selection {
    color: ${({ theme }) => theme.colors.black};
    background: ${({ theme }) => theme.colors.peach};
  }

  @font-face {
    font-family: 'Aeonik';
    src: url('/fonts/AeonikPro-Regular.woff2') format('woff2'),
        url('/fonts/AeonikPro-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Orbikular';
    src: url('/fonts/Orbikular-Light.woff2') format('woff2'),
        url('/fonts/Orbikular-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  html {
    background-color: ${({ theme }) => theme.colors.green};
    font-size: 16px;
  }

  body {
    font-family: "Aeonik", sans-serif;
    background-color: ${({ theme }) => theme.colors.green};
    color: ${({ theme }) => theme.colors.white};
    fill: ${({ theme }) => theme.colors.white};
  }

  * {
    box-sizing: border-box;
    transition-timing-function: ${(props) =>
      props.theme.animation.timingFunction.css};
  }

  main#main {
    min-height: 100vh;
  }

  *:focus-visible {
    outline: none;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: -3px;
      left: -3px;
      bottom: 0;
      right: 0;
      border: solid 3px black;
      border-radius: 4px;
    }
  }

  pre, code {
    font-weight: 400;
    margin: 1rem 0;
  }

  hr {
    border: solid 1.5px ${({ theme }) => theme.colors.peach};
    background: ${({ theme }) => theme.colors.peach};
    margin: 3rem 0;
  }

  .sans {
    font-family: "Aeonik", sans-serif;
  }

  .serif {
    font-family: "Orbikular", serif;
  }

  .peach {
    color: ${({ theme }) => theme.colors.peach};
  }

  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6,
  p,
  .p {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6 {
    margin-left: -0.02em;
    /* font-feature-settings: 'ss04' on, 'ss03' on, 'ss02' on, 'ss01' on, 'salt' on; */
    font-weight: 300;

    em {
      /* font-family: "Orbikular", serif; */
      font-family: "Aeonik", sans-serif;
    }
  }

  h1,
  .h1 {
    font-size: ${({ theme }) => theme.fonts.h1};
    line-height: 100%;
    letter-spacing: ${clampBuilder(0.0025, -0.001)};
  }

  h2,
  .h2 {
    font-size: ${({ theme }) => theme.fonts.h2};
    line-height: 110%;
    letter-spacing: ${clampBuilder(0.0025, -0.001)};
  }

  h3,
  .h3 {
    font-size: ${({ theme }) => theme.fonts.h3};
    line-height: 110%;
    letter-spacing: ${clampBuilder(0.0025, -0.001)};
  }

  h4,
  .h4 {
    font-size: ${({ theme }) => theme.fonts.h4};
    line-height: 115%;
    letter-spacing: ${clampBuilder(0.0025, -0.001)};
  }

  h5,
  .h5 {
    font-size: ${({ theme }) => theme.fonts.h5};
    letter-spacing: ${clampBuilder(0, -0.001)};
    line-height: 130%;
  }

  h6,
  .h6 {
    font-size: ${({ theme }) => theme.fonts.h6};
    letter-spacing: ${clampBuilder(0, -0.001)};
    line-height: 130%;
  }

  p,
  .p {
    font-size: ${({ theme }) => theme.fonts.paragraph};
    line-height: 215%;
    letter-spacing: 0.065em;
  }

  p.large,
  .p-large {
    font-size: ${({ theme }) => theme.fonts.paragraphLarge};
    line-height: 200%;
    letter-spacing: 0.065em;
  }

  p.small,
  .p-small {
    font-size: ${({ theme }) => theme.fonts.paragraphSmall};
    line-height: 115%;
    letter-spacing: 0.065em;
  }

  a {
    color: ${({ theme }) => theme.colors.white};
    transition: opacity ${({ theme }) => theme.animation.duration[100].css}
      ${({ theme }) => theme.animation.timingFunction.css};
    opacity: 1;

    @media (hover: hover) {
      &:hover {
        opacity: .9;
      }
    }
  }

  .gatsby-resp-image-image {
    box-shadow: none !important;
  }

  [data-hover="link"] .cursor-arrow {
    transform: translate3d(0.5rem, 0.5rem, 0) rotate(-110deg) scale(1.15);
  }

  [data-hover="paginate-prev"] {
    .cursor-arrow {
      transform: translate3d(0.5rem, 0.5rem, 0) rotate(-180deg) scale(1);
    }
    #cursor-element:after {
      --content: "Prev";
      opacity: 1;
      transform: none;
    }
  }

  [data-hover="paginate-next"] {
    .cursor-arrow {
      transform: translate3d(0.5rem, 0.5rem, 0) rotate(0deg) scale(1);
    }
    #cursor-element:after {
      --content: "Next";
      opacity: 1;
      transform: none;
    }
  }

  [data-hover="view-filter-work"] {
    .cursor-arrow {
      transform: translate3d(0.5rem, 0.5rem, 0) rotate(-110deg) scale(1.25);
    }
    #cursor-element:after {
      --content: "View Work";
      opacity: 1;
      transform: none;
    }
  }

  [data-hover="view-filter"] {
    .cursor-arrow {
      transform: translate3d(0.5rem, 0.5rem, 0) rotate(-110deg) scale(0.85);
    }
    #cursor-element:after {
      --content: "Filter";
      font-size: ${({ theme }) => theme.fonts.h6};
      opacity: 1;
      transform: none;
    }
  }

  [data-hover="view-project"] {
    .cursor-arrow {
      transform: translate3d(0.5rem, 0.5rem, 0) rotate(-110deg) scale(1.25);
    }
    #cursor-element:after {
      --content: "View Project";
      opacity: 1;
      transform: none;
    }
  }

  [data-hover="view-project-specific"] {
    .cursor-arrow {
      transform: translate3d(0.5rem, 0.5rem, 0) rotate(-110deg) scale(1.25);
    }
    #cursor-element:after {
      --content: var(--custom-content);
      opacity: 1;
      transform: none;
    }
  }

  [data-hover="hover-team-member"] {
    #cursor-element:after {
      --content: var(--custom-content);
      opacity: 1;
      transform: none;
    }
  }

  [data-hover="link-big-arrow"] {
    .cursor-arrow {
      transform: translate3d(0.5rem, 0.5rem, 0) rotate(0deg) scale(2.5);
    }
  }

  [data-hover="form"] {
    .cursor-arrow {
      opacity: 0;
    }
  }

  [data-hover="video"] {
    .cursor-arrow {
      transform: translate3d(-1.5rem, 0.65rem, 0) rotate(0deg) scale(.5);
      opacity: 0;
    }
    #cursor-element:after {
      --content: "Play";
      font-size: ${({ theme }) => theme.fonts.h3};
      transform: translate3d(-2rem, -2rem, 0);
      opacity: 1;
    }
  }

  [data-hover="text-hover"] {
    #cursor-element:after {
      --content: var(--custom-content);
      font-size: ${({ theme }) => theme.fonts.h3};
      line-height: 1;
      opacity: 1;
      transform: none;
    }
  }
`;

export default BaseStyles;
