import React from "react";
import styled from "styled-components";

/**
 *
 */
import LinkExternal from "../helpers/link-external";
import useNavData from "../../hooks/use-nav-data";
import Link from "../helpers/link";
import { siteMetadata } from "../../../gatsby-config";
import SvgLoader from "../svg/index";

/**
 * Footer component
 *
 * @param {Object} props
 * @param {Object} props.context
 */
export default function Footer({ context }) {
  const navData = useNavData();
  const d = new Date();
  let year = d.getFullYear();

  return (
    <FooterContainer id="footer" data-page={context.uid}>
      <FooterRow className="links">
        <FooterLinksWrapper>
          {navData.map(({ data, uid }, i) => {
            const pathUrl = uid === "home" ? `/` : `/${uid}`;
            return (
              <Link key={i} to={pathUrl} className="h6 serif">
                {data.title}
              </Link>
            );
          })}
        </FooterLinksWrapper>
      </FooterRow>
      <FooterRow className="logo">
        <SvgLoader svg="Logo" color="black" />
        <LegalWrapper className="p-small">
          All work is Copyright {year} {siteMetadata.author}, unless otherwise
          noted. Site by{" "}
          <LinkExternal href="https://john.design">John Choura</LinkExternal>.
        </LegalWrapper>
      </FooterRow>
    </FooterContainer>
  );
}

const FooterRow = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  &.logo {
    @media ${({ theme }) => theme.device.tablet} {
      order: -1;
    }

    svg {
      width: 8rem;
    }
  }
`;

const FooterContainer = styled.footer`
  position: relative;
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${({ theme }) => theme.layout.space[300]};
  color: ${({ theme }) => theme.colors.black};
  background-color: ${({ theme }) => theme.colors.peach};
  padding: ${({ theme }) => theme.layout.space[300]}
    ${({ theme }) => theme.layout.outerWrapperMobile};

  a {
    color: ${({ theme }) => theme.colors.black};
  }

  @media ${({ theme }) => theme.device.tablet} {
    grid-template-columns: 1fr 1fr;
    grid-gap: 0;
    padding: ${({ theme }) => theme.layout.space[200]}
      ${({ theme }) => theme.layout.outerWrapper};
  }
`;

const FooterLinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-transform: lowercase;

  a {
    text-decoration: none;
    transition: transform ${({ theme }) => theme.animation.duration[300].css}
        ${({ theme }) => theme.animation.timingFunction.css},
      color ${({ theme }) => theme.animation.duration[100].css}
        ${({ theme }) => theme.animation.timingFunction.css} !important;

    &:hover {
      transform: translateY(-0.1em);
    }

    &:active {
      transform: translateY(0.1em);
    }
  }
`;

const LegalWrapper = styled.p`
  margin: 1.5rem 0 0;
  opacity: 0.5;
  max-width: 30em;
`;
