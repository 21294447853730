import React, { useEffect } from "react";
import styled from "styled-components";
import {
  m,
  LazyMotion,
  domAnimation,
  useViewportScroll,
  AnimatePresence,
} from "framer-motion";
import Link from "../helpers/link";
import LinkExternal from "../helpers/link-external";
import Box from "../motion/motion-box";

/**
 * Data hooks
 */
import useNavData from "../../hooks/use-nav-data";
import SvgLoader from "../svg/index";
import { siteMetadata } from "../../../gatsby-config";
import NavLogo from "../nav-logo";
import { animation } from "../../style/theme";
import { NavMenu } from "./nav-menu";

const NavWrapper = styled(m.nav)`
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: space-between;
  min-height: 5.5rem;
  padding: ${({ theme }) => theme.layout.space[100]}
    ${({ theme }) => theme.layout.outerWrapperMobile} 0;
  width: 100%;
  left: 0;
  z-index: 10;

  @media ${({ theme }) => theme.device.tablet} {
    padding: ${({ theme }) => theme.layout.space[200]}
      ${({ theme }) => theme.layout.outerWrapper} 0;
  }

  svg {
    fill: ${({ theme }) => theme.colors.black};
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 200%;
    background: linear-gradient(
      180deg,
      var(--main-nav-feather) 0%,
      rgba(23, 61, 61, 0) 100%
    );
    opacity: 0;
    z-index: -1;
    pointer-events: none;
    transition: opacity ${({ theme }) => theme.animation.duration[300].css}
      ${({ theme }) => theme.animation.timingFunction.css};
  }

  &[data-min="true"] {
    &:after {
      opacity: 0.5;
    }
  }
`;

const NavLinksWrapper = styled(m.div)`
  display: none !important;

  @media ${({ theme }) => theme.device.tablet} {
    display: flex !important;
    align-items: center;
    justify-content: center;
    text-transform: lowercase;
  }

  > * {
    display: inline-flex;
    margin-left: ${({ theme }) => theme.layout.space[200]} !important;
  }

  a {
    text-decoration: none;
    color: var(--main-foreground);
    transition: transform ${({ theme }) => theme.animation.duration[300].css}
        ${({ theme }) => theme.animation.timingFunction.css},
      color ${({ theme }) => theme.animation.duration[100].css}
        ${({ theme }) => theme.animation.timingFunction.css} !important;

    &:hover {
      transform: translateY(-0.1em);
    }

    &:active {
      transform: translateY(0.1em);
    }

    &[data-active="true"] {
      color: ${({ theme }) => theme.colors.peach};
    }
  }
`;

const NavMenuButton = styled.button`
  cursor: pointer;
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  z-index: 500;
  background: transparent;
  padding: 0.25rem;
  outline: none !important;
  border: none !important;
  transition: transform ${({ theme }) => theme.animation.duration[300].css}
      ${({ theme }) => theme.animation.timingFunction.css},
    opacity ${({ theme }) => theme.animation.duration[100].css}
      ${({ theme }) => theme.animation.timingFunction.css} !important;

  &:hover {
    transform: scale(1.2);
  }

  path {
    fill: var(--main-foreground);
  }

  @media ${({ theme }) => theme.device.tablet} {
    display: none !important;
  }
`;

/**
 * Nav component
 *
 * @param {Object} props
 */
export default function Nav({ context }) {
  const navData = useNavData();
  const { scrollY } = useViewportScroll();
  const [hidden, setHidden] = React.useState(false);
  const [min, setMin] = React.useState(false);
  const [showMenu, setShowMenu] = React.useState(false);

  const update = React.useCallback(() => {
    if (scrollY?.current < scrollY?.prev) {
      setHidden(false);
      if (scrollY?.current > 50) {
        setMin(true);
      } else {
        setMin(false);
      }
    } else if (scrollY?.current > 50 && scrollY?.current > scrollY?.prev) {
      setHidden(true);
      setMin(false);
    }
  }, [scrollY]);

  useEffect(() => {
    return scrollY.onChange(() => update());
  }, [scrollY, update]);

  useEffect(() => {
    setShowMenu(false);
  }, []);

  const wrapperVariants = {
    pre: {
      opacity: 0,
      y: "-2rem",
      transition: {
        delay: 0.5,
        ease: animation.timingFunction.js,
        duration: animation.duration[300].js,
      },
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.5,
        ease: animation.timingFunction.js,
        duration: animation.duration[300].js,
      },
    },
  };

  return (
    <LazyMotion features={domAnimation}>
      <NavWrapper data-scrolled={hidden} data-min={min}>
        <Link className="button" to="/">
          <Box delay={0.25}>
            <NavLogo data-scrolled={hidden} />
          </Box>
        </Link>
        <NavLinksWrapper
          initial="pre"
          animate={hidden ? "hidden" : "visible"}
          variants={wrapperVariants}
          transition={{ ease: animation.timingFunction.js, duration: 0.5 }}
          onMouseEnter={() => setHidden(false)}
        >
          {navData.map(({ data, uid }, i) => {
            const pathUrl = uid === "home" ? `/` : `/${uid}`;
            return (
              <Link
                className="h6 serif"
                key={i}
                to={pathUrl}
                data-active={context.uri === pathUrl}
              >
                {data.title}
              </Link>
            );
          })}
          {siteMetadata.short_name === "JPGS" && ( // DELETE ME
            <LinkExternal
              href="https://github.com/johnchourajr/john-prismic-gatsby-starter"
              blank
            >
              <SvgLoader svg="GitHub" color="black" />
            </LinkExternal>
          )}
        </NavLinksWrapper>
        <NavMenuButton onClick={(e) => setShowMenu(!showMenu)}>
          <SvgLoader svg="Menu" fill="#ffffff" />
        </NavMenuButton>
      </NavWrapper>
      <AnimatePresence>
        {showMenu && (
          <NavMenu
            navData={navData}
            showMenu={showMenu}
            setShowMenu={setShowMenu}
          />
        )}
      </AnimatePresence>
    </LazyMotion>
  );
}
