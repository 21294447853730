import * as React from "react";
import styled, { css } from "styled-components";

function createTransitionDelay() {
  let styles = "";

  for (let i = 0; i < 10; i += 1) {
    styles += `
       &[data-index="${i}"] {
         transition-delay: ${i * 0.025}s;
       }
     `;
  }

  return css`
    ${styles}
  `;
}

const UpperLine = styled.g``;

const LowerLine = styled.g``;

const Logo = styled.svg`
  width: 8rem;
  transform: translateY(-0.65rem);

  path {
    transition-duration: ${({ theme }) => theme.animation.duration[100].css};
    transition-timing-function: ${({ theme }) =>
      theme.animation.timingFunction.css};
    ${createTransitionDelay()}
    will-change: transform;
  }

  &[data-scrolled="true"],
  &[data-scrolled="false"]:hover {
    path[data-text="tm"] {
      fill: ${({ theme }) => theme.colors.peach};
    }
    ${UpperLine} {
      path {
        fill: transparent;
        transform: translate3d(0, -2rem, 0);
      }
    }
    ${LowerLine} {
      path {
        fill: ${({ theme }) => theme.colors.peach};
        transform: translate3d(0, -2.2rem, 0);
      }
    }
  }
  &[data-scrolled="false"],
  &[data-scrolled="true"]:hover {
    path[data-text="tm"] {
      fill: var(--main-foreground);
    }
    ${UpperLine} {
      path {
        fill: var(--main-foreground);
        transform: translate3d(0, 0, 0);
      }
    }
    ${LowerLine} {
      path {
        fill: transparent;
        transform: translate3d(0, 0, 0);
      }
    }
  }
`;

function NavLogo(props) {
  return (
    <Logo
      width={162}
      height={32}
      viewBox="0 0 162 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M154.006 1.29227V0.541992H149.236V1.29227H151.202V6.95103H152.04V1.29227H154.006zM161.394 6.95103V0.541992H160.23L158.245 5.75781L156.252 0.541992H155.059V6.95103H155.851V1.50018L157.901 6.86064H158.553L160.602 1.54538V6.95103H161.394Z"
        fill="white"
        data-text="tm"
      />
      <UpperLine>
        <path
          d="M7.44856 32C3.34147 32 0 28.7863 0 24.8369V7.16264C0 3.2132 3.34147 0 7.44856 0C9.412 0 11.3321 0.764497 12.7178 2.09658C14.1233 3.44858 14.8981 5.24723 14.8981 7.16264V10.3541H11.6978V7.16264C11.6978 6.06964 11.2569 5.04336 10.4552 4.27238C9.66544 3.51298 8.56929 3.07745 7.44904 3.07745C5.1064 3.07745 3.20077 4.90946 3.20077 7.16264V24.8369C3.20077 27.0896 5.10592 28.9226 7.44904 28.9226C9.79216 28.9226 11.6978 27.0896 11.6978 24.8369V21.6455H14.8981V24.8369C14.8976 28.7863 11.5556 32 7.44856 32Z"
          fill="white"
          data-text="c"
          data-index="1"
        />
        <path
          d="M34.7008 9.6378C34.7008 14.5514 33.1951 16.8764 30.0931 17.666L35.0197 31.3537H31.7809L26.9906 17.9736H22.5192V31.3537H19.3262V0.644531H27.0364C32.283 0.644531 34.7008 2.79393 34.7008 8.80427V9.6378ZM22.5197 3.10158V15.517H27.0368C30.2299 15.517 31.553 13.938 31.553 9.63827V8.80473C31.553 4.72509 30.5036 3.10204 27.0368 3.10204L22.5197 3.10158Z"
          fill="white"
          data-text="r"
          data-index="2"
        />

        <path
          d="M50.552 0.644531L55.5703 31.3541H52.4683L50.9168 22.0532H42.2034L40.6977 31.3541H37.5957L42.6139 0.644531H50.552ZM42.6139 19.5971H50.552L47.8605 3.14559H45.3054L42.6139 19.5971Z"
          fill="white"
          data-text="a"
          data-index="3"
        />
        <path
          d="M83.8183 3.10158H81.4916L75.6518 31.3541H69.8578L64.0639 3.10158H61.7376V31.3541H58.5898V0.644531H66.6648L72.3219 28.8971H73.1887L78.8911 0.644531H86.9666V31.3541H83.8188L83.8183 3.10158Z"
          fill="white"
          data-text="m"
          data-index="4"
        />
        <path
          d="M106.769 22.3609C106.769 28.6344 104.579 31.3541 99.1048 31.3541C97.0059 31.3541 91.3945 31.3541 91.3945 31.3541V0.644531C91.3945 0.644531 97.0059 0.644531 99.1048 0.644531C104.579 0.644531 106.769 3.67148 106.769 9.6378V22.3609ZM94.5428 28.8971C94.5428 28.8971 97.1428 28.8971 99.1048 28.8971C102.526 28.8971 103.621 26.6597 103.621 22.3609V9.6378C103.621 5.60172 102.8 3.14559 99.1048 3.14559C97.1428 3.14559 94.5428 3.14559 94.5428 3.14559V28.8971Z"
          fill="white"
          data-text="d"
          data-index="5"
        />
        <path
          d="M114.85 31.3541V17.7544L108.053 0.644531H111.337L116.401 13.5428L121.511 0.644531H124.75L117.998 17.7544V31.3541H114.85Z"
          fill="white"
          data-text="y"
          data-index="6"
        />
        <path
          d="M130.349 31.3541H127.201V0.644531H135.412L141.845 28.8971H143.853V0.644531H147V31.3541H139.473L132.858 3.10158H130.348L130.349 31.3541Z"
          fill="white"
          data-text="n"
          data-index="7"
        />
        {/* <path
          d="M154.006 1.29227V0.541992H149.236V1.29227H151.202V6.95103H152.04V1.29227H154.006zM161.394 6.95103V0.541992H160.23L158.245 5.75781L156.252 0.541992H155.059V6.95103H155.851V1.50018L157.901 6.86064H158.553L160.602 1.54538V6.95103H161.394Z"
          fill="white"
          data-text="tm"
          data-index="8"
        /> */}
      </UpperLine>
      <LowerLine>
        <path
          d="M7.44856 67C3.34147 67 0 63.7863 0 59.8369V42.1626C0 38.2132 3.34147 35 7.44856 35C9.412 35 11.3321 35.7645 12.7178 37.0966C14.1233 38.4486 14.8981 40.2472 14.8981 42.1626V45.3541H11.6978V42.1626C11.6978 41.0696 11.2569 40.0434 10.4552 39.2724C9.66544 38.513 8.56929 38.0774 7.44904 38.0774C5.1064 38.0774 3.20077 39.9095 3.20077 42.1626V59.8369C3.20077 62.0896 5.10592 63.9226 7.44904 63.9226C9.79216 63.9226 11.6978 62.0896 11.6978 59.8369V56.6455H14.8981V59.8369C14.8976 63.7863 11.5556 67 7.44856 67Z"
          fill="#E8A791"
          data-text="c"
          data-index="1"
        />
        <path
          d="M34.7008 44.6378C34.7008 49.5514 33.1951 51.8764 30.0931 52.666L35.0197 66.3537H31.7809L26.9906 52.9736H22.5192V66.3537H19.3262V35.6445H27.0364C32.283 35.6445 34.7008 37.7939 34.7008 43.8043V44.6378ZM22.5197 38.1016V50.517H27.0368C30.2299 50.517 31.553 48.938 31.553 44.6383V43.8047C31.553 39.7251 30.5036 38.102 27.0368 38.102L22.5197 38.1016Z"
          fill="#E8A791"
          data-text="r"
          data-index="2"
        />

        <path
          d="M50.552 35.6445L55.5703 66.3541H52.4683L50.9168 57.0532H42.2034L40.6977 66.3541H37.5957L42.6139 35.6445H50.552ZM42.6139 54.5971H50.552L47.8605 38.1456H45.3054L42.6139 54.5971Z"
          fill="#E8A791"
          data-text="a"
          data-index="3"
        />
        <path
          d="M83.8183 38.1016H81.4916L75.6518 66.3541H69.8578L64.0639 38.1016H61.7376V66.3541H58.5898V35.6445H66.6648L72.3219 63.8971H73.1887L78.8911 35.6445H86.9666V66.3541H83.8188L83.8183 38.1016Z"
          fill="#E8A791"
          data-text="m"
          data-index="4"
        />
        <path
          d="M106.769 57.3609C106.769 63.6344 104.579 66.3541 99.1048 66.3541C97.0059 66.3541 91.3945 66.3541 91.3945 66.3541V35.6445C91.3945 35.6445 97.0059 35.6445 99.1048 35.6445C104.579 35.6445 106.769 38.6715 106.769 44.6378V57.3609ZM94.5428 63.8971C94.5428 63.8971 97.1428 63.8971 99.1048 63.8971C102.526 63.8971 103.621 61.6597 103.621 57.3609V44.6378C103.621 40.6017 102.8 38.1456 99.1048 38.1456C97.1428 38.1456 94.5428 38.1456 94.5428 38.1456V63.8971Z"
          fill="#E8A791"
          data-text="d"
          data-index="5"
        />
        <path
          d="M114.85 66.3541V52.7544L108.053 35.6445H111.337L116.401 48.5428L121.511 35.6445H124.75L117.998 52.7544V66.3541H114.85Z"
          fill="#E8A791"
          data-text="y"
          data-index="6"
        />
        <path
          d="M130.349 66.3541H127.201V35.6445H135.412L141.845 63.8971H143.853V35.6445H147V66.3541H139.473L132.858 38.1016H130.348L130.349 66.3541Z"
          fill="#E8A791"
          data-text="n"
          data-index="7"
        />
        {/* <path
          d="M154.006 36.2923V35.542H149.236V36.2923H151.202V41.951H152.04V36.2923H154.006zM161.394 41.951V35.542H160.23L158.245 40.7578L156.252 35.542H155.059V41.951H155.851V36.5002L157.901 41.8606H158.553L160.602 36.5454V41.951H161.394Z"
          fill="#E8A791"
          data-text="tm"
          data-index="8"
        /> */}
      </LowerLine>
    </Logo>
  );
}

export default NavLogo;
