import React, { useEffect } from "react";
import { m, LazyMotion, domAnimation, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { animation } from "../../../style/theme";
import styled from "styled-components";

const Div = styled(m.div)`
  will-change: transform;
`;

export default function Box({
  children,
  transitionOverrides,
  hiddenStyles,
  visibleStyles,
  triggerOnce = true,
  delay = 0.15,
  ...rest
}) {
  const controls = useAnimation();
  const { ref, inView } = useInView({ triggerOnce: triggerOnce });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
    if (!inView) {
      controls.start("hidden");
    }
  }, [controls, inView]);

  const boxVariants = {
    hidden: { opacity: 0, scale: 1, y: -10, ...hiddenStyles },
    visible: {
      opacity: 1,
      scale: 1,
      y: 0,
      ...visibleStyles,
      transition: {
        ease: animation.timingFunction.js,
        duration: animation.duration[300].js,
        delay: delay,
        ...transitionOverrides,
      },
    },
  };
  return (
    <LazyMotion features={domAnimation}>
      <Div
        ref={ref}
        className="box"
        initial="hidden"
        exit="hidden"
        animate={controls}
        variants={boxVariants}
        {...rest}
      >
        {children}
      </Div>
    </LazyMotion>
  );
}
