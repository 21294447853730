import { useStaticQuery, graphql } from "gatsby";

export const useNavData = () => {
  const data = useStaticQuery(
    graphql`
      query navDataQuery {
        allPrismicWorkpage(
          filter: {
            data: { hide_in_nav: { eq: false }, top_level_page: { eq: true } }
          }
          sort: { order: ASC, fields: data___nav_order }
        ) {
          edges {
            node {
              id
              uid
              url
              data {
                title
                nav_order
              }
            }
          }
        }
        allPrismicContactpage(
          filter: {
            data: { hide_in_nav: { eq: false }, top_level_page: { eq: true } }
          }
          sort: { order: ASC, fields: data___nav_order }
        ) {
          edges {
            node {
              id
              uid
              url
              data {
                title
                nav_order
              }
            }
          }
        }
        allPrismicStudiopage(
          filter: {
            data: { hide_in_nav: { eq: false }, top_level_page: { eq: true } }
          }
          sort: { order: ASC, fields: data___nav_order }
        ) {
          edges {
            node {
              id
              uid
              url
              data {
                title
                nav_order
              }
            }
          }
        }
      }
    `
  );

  const {
    allPrismicWorkpage,
    allPrismicContactpage,
    allPrismicStudiopage,
  } = data;

  const allNav = [
    ...allPrismicWorkpage.edges.map((e) => e.node),
    ...allPrismicContactpage.edges.map((e) => e.node),
    ...allPrismicStudiopage.edges.map((e) => e.node),
  ].sort((a, b) => {
    return a.data.nav_order < b.data.nav_order ? -1 : 1;
  });

  return allNav;
};

export default useNavData;
