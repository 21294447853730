import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 11.935L23 4v3.916L10.12 14.99v1.075L23 23.083V27L9 19.122v-7.187z"
        fill="#173D3D"
      />
    </svg>
  );
}

export default SvgComponent;
