import { clampBuilder } from "../functions/util";

/**
 * size const
 *
 * All screen sizes in pixel value
 */
export const size = {
  mobileSm: "320px",
  mobile: "375px",
  mobileLg: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopLg: "1440px",
  desktop: "1920px",
  desktopLg: "2560px",
};

/**
 * device const
 *
 * All device '@media' queries sizes in pixel value
 */
export const device = {
  mobileSm: `(min-width: ${size.mobileSm})`,
  mobile: `(min-width: ${size.mobile})`,
  mobileLg: `(min-width: ${size.mobileLg})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopLg: `(min-width: ${size.laptopLg})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopLg: `(min-width: ${size.desktopLg})`,
};

/**
 * colors const
 *
 * All colors
 */
export const colors = {
  black: "#292A2C",
  hardBlack: "#1B1C1D",
  white: "#ffffff",
  green: "#173D3D",
  peach: "#E8A791",
};

/**
 * colorPlates const
 *
 * All colors
 */
export const colorPlates = {
  primary: {
    foreground: colors.white,
    background: colors.green,
    border: colors.peach,
    icon: "white",
  },
  secondary: {
    foreground: colors.black,
    background: colors.paper,
    border: colors.black,
    icon: "black",
  },
};

/**
 * fonts const
 *
 * All dynamic font sizes
 *
 * The min end of the scale uses the
 * modular scale of 1.125 (https://www.modularscale.com/?1&em&1.125)
 *
 * The max end of the scale uses the
 * modular scale of 1.5 (https://www.modularscale.com/?1&em&1.5)
 *
 * In this use, the first number is 44px divided by 16px, which
 * outputs 2.75rem into the clampBuilder function
 */
export const fonts = {
  h1: `${clampBuilder(48 / 16, 170 / 16)}`,
  h2: `${clampBuilder(40 / 16, 98 / 16)}`,
  h3: `${clampBuilder(36 / 16, 72 / 16)}`,
  h4: `${clampBuilder(30 / 16, 48 / 16)}`,
  h5: `${clampBuilder(24 / 16, 36 / 16)}`,
  h6: `${clampBuilder(20 / 16, 24 / 16)}`,
  paragraphLarge: "1.25rem",
  paragraph: "1rem",
  paragraphSmall: "0.6875rem",
};
/**
 * animation const
 *
 * All animation variables
 */
export const animation = {
  timingFunction: {
    js: [0.25, 1, 0.5, 1],
    css: `cubic-bezier(0.25, 1, 0.5, 1)`,
  },
  duration: {
    "-100": {
      js: 0.25,
      css: `250ms`,
    },
    100: {
      js: 0.5,
      css: `500ms`,
    },
    200: {
      js: 0.7,
      css: `700ms`,
    },
    300: {
      js: 1,
      css: `1000ms`,
    },
  },
};

/**
 * layout const
 *
 * All layout variables
 */
export const layout = {
  outerWrapperMobile: "1.5em",
  outerWrapper: "4.6875vw",
  space: {
    100: "1em",
    200: "4vw",
    300: "8vw",
  },
};

export default {
  device: device,
  colors: colors,
  animation: animation,
  size: size,
  fonts: fonts,
  layout: layout,
};
